import { useEffect } from 'react';
import { useAppContainerContext } from '../AppContainer/useAppContainerContext';
import { OrderSummaryContainerWrapper, OrderSummaryModalWrapper } from './styles';

type OrderSummaryContainerProps = {
  children: React.ReactNode;
  backgroundColor?: string;
};

export const OrderSummaryContainer = ({
  backgroundColor,
  children,
}: OrderSummaryContainerProps) => {
  const { setBackgroundColor } = useAppContainerContext();

  useEffect(() => {
    if (backgroundColor) {
      setBackgroundColor(backgroundColor);
    }
  }, [backgroundColor, setBackgroundColor]);

  return (
    <OrderSummaryModalWrapper>
      <OrderSummaryContainerWrapper>{children}</OrderSummaryContainerWrapper>
    </OrderSummaryModalWrapper>
  );
};
