import { useEffect, type RefObject, type MouseEvent, useCallback } from 'react';

function ClickedOutside(ref: RefObject<HTMLDivElement>, onClicked: (close: boolean) => void) {
  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent | Event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setTimeout(() => {
          onClicked(false);
        }, 0);
      }
    },
    [ref, onClicked],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [handleClickOutside]);

  return null;
}
export default ClickedOutside;
