import { useState, type ChangeEvent } from 'react';
import {
  InnerLabelWrapper,
  InputLabel,
  OuterLabelWrapper,
  RequiredIndicator,
} from '../../../style';
import {
  CharacterCounter,
  StyledTextArea,
  TextAreaError,
  TextAreaFooter,
  TextAreaWrapper,
} from './style';

export type TextAreaProps = {
  label?: string;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  errorMessage?: string;
  characterLimit?: number;
  fullWidth?: boolean;
  rows?: number;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
};

export const TextArea = ({
  label,
  defaultValue,
  placeholder,
  disabled,
  required,
  errorMessage,
  characterLimit,
  fullWidth,
  rows = 3,
  onChange,
  onBlur,
}: TextAreaProps) => {
  const [currentCount, setCurrentCount] = useState(defaultValue ? defaultValue.length : 0);

  return (
    <TextAreaWrapper fullWidth={fullWidth}>
      <OuterLabelWrapper>
        <InnerLabelWrapper>
          {label && <InputLabel>{label}</InputLabel>}
          {!!required && <RequiredIndicator>*</RequiredIndicator>}
        </InnerLabelWrapper>
      </OuterLabelWrapper>
      <StyledTextArea
        data-testid="text-area"
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        error={errorMessage ? true : false}
        rows={rows}
        cols={45}
        maxLength={characterLimit}
        onBlur={onBlur}
        onChange={(e) => {
          setCurrentCount(e.target.value.length);
          onChange && onChange(e);
        }}
      />
      {(characterLimit || errorMessage) && (
        <TextAreaFooter>
          {errorMessage && <TextAreaError>{errorMessage}</TextAreaError>}
          {characterLimit && (
            <CharacterCounter>
              {currentCount}/{characterLimit}
            </CharacterCounter>
          )}
        </TextAreaFooter>
      )}
    </TextAreaWrapper>
  );
};
