import React from 'react';
import { PORTAL_DISABLE_REACT_STRICT_MODE } from './environment';

export const StrictMode = ({ children }: { children: React.ReactNode }) => {
  if (PORTAL_DISABLE_REACT_STRICT_MODE) {
    return <>{children}</>;
  }

  return <React.StrictMode>{children}</React.StrictMode>;
};
