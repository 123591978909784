import { useContext } from 'react';
import { type SegmentButtonProps } from '../../types/segmentButtons';
import { SegmentedButtonsContexts } from '../../context/SegmentedButtonsContext';
import { StyledSegmentedButton } from './styles';

export const SegmentButton = ({ value, label, testId }: SegmentButtonProps) => {
  const { selectedValue, setSelectedValue } = useContext(SegmentedButtonsContexts.useCurrentIndex);

  const handleClick = (value: string) => {
    setSelectedValue(value);
  };

  return (
    <StyledSegmentedButton
      active={selectedValue === value}
      onClick={() => {
        handleClick(value);
      }}
      data-testid={testId}
    >
      {label}
    </StyledSegmentedButton>
  );
};
