import { type ReactNode } from 'react';
import { CssGrid, CssGridItem } from './styles';

export type GridProps = {
  children?: ReactNode;
  columns?: string | number | number[];
  gap?: string | number | null | (string | number | null)[];
  rowGap?: string | number | null | (string | number | null)[];
  testId?: string;
};

export type GridItem = {
  children?: ReactNode;
  gridColumn?: string | number | null | (string | number | null)[];
  gridRow?: string | number | null | (string | number | null)[];
};

export const Grid = ({ children, columns, rowGap, gap, testId }: GridProps) => {
  return (
    <CssGrid data-testid={testId ?? 'grid'} gap={gap} columns={columns} rowGap={rowGap}>
      {children}
    </CssGrid>
  );
};

export const GridItem = ({ children, ...rest }: GridItem) => {
  return <CssGridItem {...rest}>{children}</CssGridItem>;
};
