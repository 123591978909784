import { type ReactNode } from 'react';
import { type SegmentedButtonsProviderProps } from '../types/segmentButtons';
import { SegmentedButtonsProvider } from '../context/SegmentedButtonsContext';

export const withSegmentedButtonsProvider = (InputComponent: React.FC<{ children: ReactNode }>) => {
  const WithProvider = (props: SegmentedButtonsProviderProps) => {
    return (
      <SegmentedButtonsProvider {...props}>
        <InputComponent>{props.children}</InputComponent>
      </SegmentedButtonsProvider>
    );
  };

  WithProvider.displayName = 'WithProvider';

  return WithProvider;
};
