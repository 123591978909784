import { useDeferredValue, useEffect, useState } from 'react';
import { type ListDropdownItem } from '@stenarecycling/customer-portal-types';

export const useDropdownItems = (prop: { items: ListDropdownItem[] }) => {
  const [items, setItems] = useState<ListDropdownItem[]>(prop.items);
  const deferredItems = useDeferredValue(items);

  useEffect(() => {
    setItems(prop.items);
  }, [prop.items]);

  return {
    items: deferredItems,
    setItems,
  };
};
