import { useContext } from 'react';
import { DropdownContexts } from '../../context/DropdownContext';
import { Searchfield } from '../../../Text/Searchfield';
import { DropdownSearchWrapper } from './styles';

export const DropdownSearch = () => {
  const { searchPlaceholder } = useContext(DropdownContexts.props);
  const { onSearch, resetSearch, searchText } = useContext(DropdownContexts.useSearch);

  const handleSearch = async (val: string) => {
    await onSearch(val);
  };

  return (
    <DropdownSearchWrapper>
      <Searchfield
        defaultValue={searchText}
        onChange={(value) => handleSearch(value)}
        onClear={resetSearch}
        placeholder={searchPlaceholder}
      />
    </DropdownSearchWrapper>
  );
};
