import { type PartnerResItem } from '@stenarecycling/customer-portal-types';
import { getPartnerDisplay } from '@stenarecycling/customer-portal-utils';
import { Paragraph } from 'component-library';
import { LocationWrapper, PartnerDescription } from './styles';

type PartnerInfoProps = {
  partner?: PartnerResItem;
};

/*
This component is used to display partner information in a unified manner
*/
export const PartnerInfo = ({ partner }: PartnerInfoProps) => {
  if (!partner) {
    return null;
  }
  const { nameAndText, addressAndId } = getPartnerDisplay(partner);

  return (
    <LocationWrapper>
      <Paragraph variant="large">{nameAndText}</Paragraph>
      <PartnerDescription>{addressAndId}</PartnerDescription>
    </LocationWrapper>
  );
};
