import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../../../Typography';
import { spacing } from '../../../../../themes';
import { ActionsWrapper, ContentWrapper, EmptyTableWrapper, StyledParagraph } from './style';

export type TableEmptyProps = {
  title?: string;
  message?: string;
  actions?: ReactNode[];
};

export const TableEmpty = ({ title, message, actions }: TableEmptyProps) => {
  const { t } = useTranslation('', { keyPrefix: 'component.table' });

  return (
    <EmptyTableWrapper>
      <ContentWrapper>
        <Heading variant="medium" as="h2" mb={spacing.xsmall} align="center">
          {title ?? t('tableEmpty.title')}
        </Heading>
        <StyledParagraph variant="large">{message ?? t('tableEmpty.message')}</StyledParagraph>
        {actions && (
          <ActionsWrapper>
            {actions.map((action, index) => {
              return <div key={index}>{action}</div>;
            })}
          </ActionsWrapper>
        )}
      </ContentWrapper>
    </EmptyTableWrapper>
  );
};
