import { type PictogramIconProps } from './types';

// finance_11
export const DollarSign = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.9033 42.3744C36.9033 33.7117 45.8307 27.293 55.995 27.293C66.1593 27.293 75.0867 33.7117 75.0867 42.3744C75.0867 43.2028 74.4151 43.8744 73.5867 43.8744C72.7582 43.8744 72.0867 43.2028 72.0867 42.3744C72.0867 36.0432 65.2694 30.293 55.995 30.293C46.7206 30.293 39.9033 36.0432 39.9033 42.3744C39.9033 45.4249 41.5492 47.6518 44.5489 49.5739C47.6064 51.5332 51.8164 53.0033 56.4651 54.5413C61.0419 56.0506 65.6273 57.6249 69.0593 59.8228C72.5495 62.0579 75.0867 65.1007 75.0867 69.5472C75.0867 78.2099 66.1593 84.6286 55.995 84.6286C45.8314 84.6286 36.9033 78.2204 36.9033 69.5472C36.9033 68.7188 37.5749 68.0472 38.4033 68.0472C39.2317 68.0472 39.9033 68.7188 39.9033 69.5472C39.9033 75.8876 46.7198 81.6286 55.995 81.6286C65.2694 81.6286 72.0867 75.8784 72.0867 69.5472C72.0867 66.4967 70.4408 64.2699 67.4414 62.3491C64.3841 60.3912 60.1738 58.9232 55.5252 57.3903L55.5239 57.3898C50.9475 55.8758 46.362 54.2989 42.9303 52.0998C39.4405 49.8636 36.9033 46.8209 36.9033 42.3744Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.9951 16.872C56.8235 16.872 57.4951 17.5436 57.4951 18.372V27.7556C57.4951 28.584 56.8235 29.2556 55.9951 29.2556C55.1667 29.2556 54.4951 28.584 54.4951 27.7556V18.372C54.4951 17.5436 55.1667 16.872 55.9951 16.872Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.9951 82.7441C56.8235 82.7441 57.4951 83.4157 57.4951 84.2441V93.6277C57.4951 94.4561 56.8235 95.1277 55.9951 95.1277C55.1667 95.1277 54.4951 94.4561 54.4951 93.6277V84.2441C54.4951 83.4157 55.1667 82.7441 55.9951 82.7441Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  );
};
