import {
  Country,
  type PartnerResItem,
  type PartnerTreeNodeV2,
} from '@stenarecycling/customer-portal-types';

import { getPartnerDisplay } from '@stenarecycling/customer-portal-utils';
import { type LocationListItemsType } from '../DropdownV2/components/DropdownList/Lists/LocationPicker/types';

export const LocationCountTemplate = '#COUNT';

export const partnerTreesToListDropdown = (
  partners: PartnerTreeNodeV2[],
  shouldExcludeInactiveValues: boolean,
  level: number,
  allowSelectingBusinessPartners: boolean,
  hideNBPPrefix?: boolean,
  parentName?: string,
): LocationListItemsType[] => {
  const items = partners.sort(sortFNOPartners).map((partner) => {
    if (hideNBPPrefix) {
      partner.item.name = partner.item.name
        .replace('(NBP Ej Syd)', '')
        .replace('(NBP Syd)', '')
        .trim();
    }

    const _isMergedItem = isMergedItem(partner);

    const { nameAndText, addressAndId } = makePartnerLabels(partner, level, parentName);
    const isActive = getIsPartnerActive(partner);
    const isUnselectable =
      !_isMergedItem && getIsPartnerUnselectable(partner, allowSelectingBusinessPartners);

    const firstChild = partner.children[0];
    const dwKey = _isMergedItem ? firstChild.item.dwKey : partner.item.dwKey;
    const childCount = _isMergedItem ? firstChild.item.childCount : partner.item.childCount;
    const locationCount = _isMergedItem
      ? firstChild.item.locationCount
      : partner.item.locationCount;
    const isLocation = _isMergedItem ? true : partner.item.isLocation;

    let type: 'group' | 'value' = isUnselectable ? 'group' : 'value';

    if (_isMergedItem) {
      type = 'value';
    }

    return {
      value: dwKey,
      label: nameAndText,
      altLabel: getFullLabel(partner),
      meta: {
        description: addressAndId,
        childCount: childCount ?? 0,
        locationCount: locationCount,
        isLocation: !!isLocation,
        isActive,
        shouldExcludeInactiveValues,
        isUnselectable,
        activeChildren: !!partner.item.hasActiveChildren,
        source: partner.item.source,
      },
      type,
      children: _isMergedItem
        ? []
        : partnerTreesToListDropdown(
            partner.children,
            shouldExcludeInactiveValues,
            level + 1,
            allowSelectingBusinessPartners,
            hideNBPPrefix,
            partner.item.name,
          ),
    } satisfies LocationListItemsType;
  });

  return items;
};

const isMergedItem = (partner: PartnerTreeNodeV2) => {
  const isAddress = partner.item.type === 'DELIVERYADRESS';

  return (
    partner.item.source === 'FNO' &&
    !isAddress &&
    partner.item.locationCount === 1 &&
    partner.item.childCount === 1 &&
    !!partner.children[0] &&
    !!partner.children[0].item.isLocation
  );
};

const getFullLabel = (partner: PartnerTreeNodeV2) => {
  if (partner.item.source !== 'FNO') {
    return;
  }

  const isMergeItem = isMergedItem(partner);

  if (isMergeItem) {
    let label = partner.item.name;

    const childDescription = partner.children[0]?.item.description;

    if (childDescription) {
      label += `, ${childDescription}`;
    }

    return label;
  }

  const display = getPartnerDisplay(partner.item);

  return display.nameAndText;
};

const getIsPartnerUnselectable = (
  partner: PartnerTreeNodeV2,
  allowSelectingBusinessPartners: boolean,
) => {
  if (allowSelectingBusinessPartners) return false;
  if (partner.item.source === 'FNO' || partner.item.source === 'CS') {
    return !partner.item.isLocation;
  }

  return false;
};

const makePartnerLabels = (partner: PartnerTreeNodeV2, level: number, parentName?: string) => {
  if (partner.item.source === 'FNO') {
    let nameAndText = '';
    let addressAndId = '';

    const isAddress = partner.item.type === 'DELIVERYADRESS';

    const _isMergedItem = isMergedItem(partner);

    if (level === 0) {
      nameAndText = partner.item.name;
      if (partner.item.description) {
        nameAndText += `, ${partner.item.description}`;
      }

      if (_isMergedItem) {
        const childDescription = partner.children[0]?.item.description;

        if (childDescription) {
          nameAndText += `, ${childDescription}`;
        }
      }
    }

    if (level > 0) {
      if (parentName !== partner.item.name) {
        nameAndText = partner.item.name;
        if (partner.item.description) {
          nameAndText += `, ${partner.item.description}`;
        }
      } else {
        nameAndText = partner.item.description || partner.item.name;
      }
      if (_isMergedItem) {
        const childDescription = partner.children[0]?.item.description;

        if (childDescription) {
          nameAndText = childDescription;
        }
      }
    }

    if (isAddress) {
      addressAndId = `${getAddrFromItem(partner.item)} (${getParentBPId(partner.item.dwKey)})`;
    } else if (_isMergedItem) {
      const childItem = partner.children[0].item;

      addressAndId = `${getAddrFromItem(childItem)} (${partner.item.id})`;
    } else {
      addressAndId = `${LocationCountTemplate} (${partner.item.id})`;
    }

    return { nameAndText, addressAndId };
  }

  return getPartnerDisplay(partner.item);
};

const getParentBPId = (partnerId: string) => {
  const [, bpId] = partnerId.split('_');

  return bpId;
};

const getAddrFromItem = (item: PartnerResItem) => {
  let desc = '';

  desc += item.street;
  desc += item.street ? ', ' : '';
  desc += item.city;

  return desc;
};

export const getIsPartnerActive = (partner: PartnerTreeNodeV2) => {
  const _isMergedItem = isMergedItem(partner);

  if (_isMergedItem) {
    return partner.children[0].item.isActive;
  }

  if (partner.item.companyId === Country.SWEDEN) {
    return partner.item.isActive;
  }

  return partner.item.isActivePickupPlace;
};

export const hasInactiveChildren = (partners: PartnerTreeNodeV2[]): boolean => {
  return partners.some((partner) => {
    const isActive = getIsPartnerActive(partner);

    if (!isActive) {
      return true;
    }

    return hasInactiveChildren(partner.children);
  });
};

const sortFNOPartners = (a: PartnerTreeNodeV2, b: PartnerTreeNodeV2) => {
  if (a.item.source !== 'FNO' || b.item.source !== 'FNO') {
    return 0;
  }

  //Sort DeliveryAdress first
  if (a.item.type === 'DELIVERYADRESS' && b.item.type !== 'DELIVERYADRESS') {
    return -1;
  }

  if (a.item.type !== 'DELIVERYADRESS' && b.item.type === 'DELIVERYADRESS') {
    return 1;
  }

  //Sort merged item next

  const aIsMergedItem = isMergedItem(a);
  const bIsMergedItem = isMergedItem(b);

  if (aIsMergedItem && !bIsMergedItem) {
    return -1;
  }

  if (!aIsMergedItem && bIsMergedItem) {
    return 1;
  }

  //Sort by name
  return a.item.name.localeCompare(b.item.name);
};
