import { useContext } from 'react';
import { type ListDropdownItem } from '@stenarecycling/customer-portal-types';
import { DropdownContexts } from '../../../context/DropdownContext';
import { ListItemWrapper } from '../style';

type ListProps = {
  children?: React.ReactNode;
  item: ListDropdownItem;
};

export const ListItemSelectSimple = ({ children, item }: ListProps) => {
  const { toggleValues, isItemSelected } = useContext(DropdownContexts.useValues);
  const { toggleOpen } = useContext(DropdownContexts.useDropdown);
  const handleClick = () => {
    toggleOpen();
    toggleValues([item.value]);
  };

  return (
    <ListItemWrapper
      isSelected={!!isItemSelected(item)}
      onClick={handleClick}
      data-testid="list-item"
    >
      {children}
    </ListItemWrapper>
  );
};
