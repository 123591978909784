import { type PictogramIconProps } from './types';

// buildings_01
export const Factory = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4954 17.2614C21.5245 16.4545 22.187 15.8154 22.9944 15.8154H32.6743C33.4816 15.8154 34.1442 16.4545 34.1733 17.2614L35.479 53.4629L52.6103 39.5697C53.0595 39.2054 53.6782 39.1318 54.2004 39.3807C54.7225 39.6295 55.0551 40.1564 55.0551 40.7348V52.4966L71.033 39.5687C71.4824 39.205 72.1008 39.132 72.6225 39.381C73.1443 39.63 73.4765 40.1567 73.4765 40.7348V52.4977L89.4645 39.5684C89.914 39.205 90.5323 39.1321 91.054 39.3811C91.5756 39.6301 91.9077 40.1567 91.9077 40.7348V94.6852C91.9077 95.5136 91.2362 96.1852 90.4077 96.1852H21.5918C20.7634 96.1852 20.0918 95.5136 20.0918 94.6852V56.2028C20.0918 56.1848 20.0921 56.1668 20.0928 56.1487L21.4954 17.2614ZM24.4413 18.8154L23.0918 56.2299V93.1852H88.9077V43.8769L72.9197 56.8061C72.4702 57.1696 71.8519 57.2425 71.3303 56.9935C70.8086 56.7444 70.4765 56.2178 70.4765 55.6398V43.878L54.4986 56.8059C54.0492 57.1695 53.4308 57.2425 52.9091 56.9935C52.3873 56.7446 52.0551 56.2179 52.0551 55.6398V43.8825L35.4266 57.3679C35.1594 57.5846 34.8259 57.7028 34.4818 57.7028H34.0768C33.2695 57.7028 32.6069 57.0637 32.5778 56.2569L31.2274 18.8154H24.4413Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9385 68.875C32.9385 68.3227 33.3862 67.875 33.9385 67.875H45.8408C46.393 67.875 46.8408 68.3227 46.8408 68.875V77.3992C46.8408 77.9515 46.393 78.3992 45.8408 78.3992H33.9385C33.3862 78.3992 32.9385 77.9515 32.9385 77.3992V68.875ZM34.9385 69.875V76.3992H44.8408V69.875H34.9385Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.0488 68.8752C49.0488 68.3229 49.4965 67.8752 50.0488 67.8752H61.9511C62.5034 67.8752 62.9511 68.3229 62.9511 68.8752V77.3994C62.9511 77.9517 62.5034 78.3994 61.9511 78.3994H50.0488C49.4965 78.3994 49.0488 77.9517 49.0488 77.3994V68.8752ZM51.0488 69.8752V76.3994H60.9511V69.8752H51.0488Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.1688 68.8752C65.1688 68.3229 65.6165 67.8752 66.1688 67.8752H78.0711C78.6234 67.8752 79.0711 68.3229 79.0711 68.8752V77.3994C79.0711 77.9517 78.6234 78.3994 78.0711 78.3994H66.1688C65.6165 78.3994 65.1688 77.9517 65.1688 77.3994V68.8752ZM67.1688 69.8752V76.3994H77.0711V69.8752H67.1688Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  );
};
