import React from 'react';
import { type ColumnType, type RowType } from '../types';

const calculateCellWidth = (columns: ColumnType[], rows: RowType[]): Record<string, number> => {
  const colspanObj: Record<string, number> = {};

  if (rows.length) {
    // Calculate the maximum textlength of rowsValues per column
    rows.forEach((row) => {
      columns.forEach((column, columnIndex) => {
        const key = column.fieldName;
        let textLength = 0;

        if (row.cells[columnIndex] === undefined) return;

        const cell = row.cells[columnIndex].content as React.JSX.Element;

        const textChild = getLongestChild(cell);

        textLength = textChild.length;

        // Check if column name is larger than textChild.
        if (column.name.length > textLength) textLength = column.name.length;

        // Check if subLabel is larger than textChild.
        if (column.subLabel && column.subLabel.length > textLength)
          textLength = column.subLabel.length;

        if (colspanObj[key] !== undefined) {
          if (colspanObj[key] < textLength) colspanObj[key] = textLength;
        } else {
          colspanObj[key] = textLength;
        }
      });
    });
  } else {
    columns.forEach((column) => {
      const key = column.fieldName;
      const textLength = column.name.length;

      if (colspanObj[key] !== undefined) {
        if (colspanObj[key] < textLength) colspanObj[key] = textLength;
      } else {
        colspanObj[key] = textLength;
      }
    });
  }

  // Convert textLength to colspan
  Object.keys(colspanObj).forEach((key) => {
    const column = columns.find((col) => col.fieldName === key);

    // If column has colspan defined, use that value instead of calculated value
    if (column?.colSpan) {
      colspanObj[key] = column.colSpan;
    } else {
      const textLength = colspanObj[key];

      let dividedValue = Math.ceil(textLength / 2.9);

      // Min colspan is 2
      if (dividedValue < 2) dividedValue = 2;
      colspanObj[key] = dividedValue;
    }
  });

  return colspanObj;
};

export default calculateCellWidth;

const getLongestChild = (children: React.ReactNode): string => {
  let longestChild = '';

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      const childText = getLongestChild(child.props.children);

      if (childText.length > longestChild.length) longestChild = childText;
    } else if (child !== null && child !== undefined) {
      const childLength = `${child}`.length;

      if (childLength > longestChild.length) longestChild = `${child}`;
    }
  });

  return longestChild;
};
