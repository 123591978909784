import { type Spacing } from '../../../themes/stena-recycling/spacing';
import { StyledDivider } from './styles';

export type DividerProps = {
  p?: Spacing;
  pt?: Spacing;
  pb?: Spacing;
  m?: Spacing;
  mt?: Spacing;
  mb?: Spacing;
} & React.HTMLAttributes<HTMLDivElement>;

export const Divider = (props: DividerProps) => {
  return <StyledDivider {...props} />;
};
