import { useDeferredValue, useState } from 'react';
import { type ListDropdownItem } from '@stenarecycling/customer-portal-types';
import { type DropdownOnSearch } from '../../types/dropdown';
import { searchItems } from './utils';

let SEARCH_THROTTLE: NodeJS.Timeout | '' = '';

export const useDropdownSearch = (prop: {
  items: ListDropdownItem[];
  customSearch?: DropdownOnSearch;
}) => {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<ListDropdownItem[]>([]);
  const deferredSearchResults = useDeferredValue(searchResults);

  const onSearch = async (search: string) => {
    // If search empty, reset dropdown search
    if (search === '') {
      resetSearch();

      return;
    }

    clearInterval(SEARCH_THROTTLE);

    SEARCH_THROTTLE = setTimeout(async () => {
      if (prop.customSearch) {
        setSearchText(search);
        const filteredItems = await prop.customSearch(prop.items, search);

        setSearchResults(filteredItems);
      } else {
        const searchResult = searchItems(prop.items, search);

        setSearchResults(searchResult);
        setSearchText(search);
      }
    }, 200);
  };

  const resetSearch = () => {
    setSearchText('');
    setSearchResults([]);
  };

  return {
    onSearch,
    resetSearch,
    searchResults: deferredSearchResults,
    searchText,
    searchActive: searchText.length > 0,
    hasSearchResults: searchResults.length > 0,
    searchResultsEmpty: searchResults.length === 0,
  };
};
