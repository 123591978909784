import {
  type ArticleListDropdownItem,
  type ListDropdownItem,
} from '@stenarecycling/customer-portal-types';

const itemSearchFilter = (item: ArticleListDropdownItem, searchLower: string) => {
  const description = item.meta?.description ?? '';

  const itemString = `${item.label}.${item.value}.${description}`.toLowerCase();

  return itemString.includes(searchLower);
};

const itemIsInSearch = (item: ListDropdownItem, searchLower: string): boolean => {
  return itemSearchFilter(item, searchLower);
};

export const filterItems = (
  items: ListDropdownItem[],
  searchLower: string,
  inputParentInSearch: boolean,
  parentIsGroup: boolean,
  inputGroupIsInSearch: boolean,
): ListDropdownItem[] => {
  const filteredItems: ListDropdownItem[] = [];

  for (const item of items) {
    const isInSearch = itemIsInSearch(item, searchLower);
    const itemIsDeclaration = item.meta?.valueType === 'declaration';
    const parentInSearch = isInSearch || inputParentInSearch;
    const groupIsInSearch = inputGroupIsInSearch || (parentInSearch && parentIsGroup);

    if (isInSearch || (parentInSearch && itemIsDeclaration && parentIsGroup) || groupIsInSearch) {
      const filteredChildren = filterItems(
        item.children ?? [],
        searchLower,
        parentInSearch,
        item.type === 'group',
        groupIsInSearch,
      );

      filteredItems.push({ ...item, children: filteredChildren });
    } else {
      const filteredChildren = filterItems(
        item.children ?? [],
        searchLower,
        parentInSearch,
        item.type === 'group',
        groupIsInSearch,
      );

      if (filteredChildren.length > 0) {
        filteredItems.push({ ...item, children: filteredChildren });
      }
    }
  }

  return filteredItems;
};

export const articleSearchItems = async (
  inputItems: ListDropdownItem[],
  search: string,
): Promise<ListDropdownItem[]> => {
  const searchLower = search.toLowerCase();
  const items = [...inputItems];
  const filteredItems = filterItems(items, searchLower, false, false, false);

  return filteredItems;
};
