import { type PictogramIconProps } from './types';

// vehicles_08
export const GarbageTruck = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6791_15775)">
        <path
          d="M28.7546 80.0001C25.0421 80.0001 22.0264 77.0131 22.0264 73.336C22.0264 69.6589 25.0421 66.6719 28.7546 66.6719C32.4671 66.6719 35.4828 69.6589 35.4828 73.336C35.4828 77.0131 32.4671 80.0001 28.7546 80.0001ZM28.7546 69.6293C26.6943 69.6293 25.0123 71.2953 25.0123 73.336C25.0123 75.3766 26.6943 77.0426 28.7546 77.0426C30.8149 77.0426 32.4969 75.3766 32.4969 73.336C32.4969 71.2953 30.8149 69.6293 28.7546 69.6293Z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="M42.9277 80.0001C39.2152 80.0001 36.1995 77.0131 36.1995 73.336C36.1995 69.6589 39.2152 66.6719 42.9277 66.6719C46.6402 66.6719 49.6559 69.6589 49.6559 73.336C49.6559 77.0131 46.6402 80.0001 42.9277 80.0001ZM42.9277 69.6293C40.8674 69.6293 39.1854 71.2953 39.1854 73.336C39.1854 75.3766 40.8674 77.0426 42.9277 77.0426C44.988 77.0426 46.67 75.3766 46.67 73.336C46.67 71.2953 44.988 69.6293 42.9277 69.6293Z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="M88.7314 80.0001C85.0189 80.0001 82.0032 77.0131 82.0032 73.336C82.0032 69.6589 85.0189 66.6719 88.7314 66.6719C92.4439 66.6719 95.4596 69.6589 95.4596 73.336C95.4596 77.0131 92.4439 80.0001 88.7314 80.0001ZM88.7314 69.6293C86.6711 69.6293 84.9891 71.2953 84.9891 73.336C84.9891 75.3766 86.6711 77.0426 88.7314 77.0426C90.7917 77.0426 92.4737 75.3766 92.4737 73.336C92.4737 71.2953 90.7917 69.6293 88.7314 69.6293Z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="M83.2075 76.6977H49.2478C48.4217 76.6977 47.7549 76.0372 47.7549 75.219C47.7549 74.4007 48.4217 73.7402 49.2478 73.7402H83.2075C84.0336 73.7402 84.7004 74.4007 84.7004 75.219C84.7004 76.0372 84.0336 76.6977 83.2075 76.6977Z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="M35.9599 75.3071H35.2234C34.3973 75.3071 33.7305 74.6466 33.7305 73.8283C33.7305 73.0101 34.3973 72.3496 35.2234 72.3496H35.9599C36.786 72.3496 37.4529 73.0101 37.4529 73.8283C37.4529 74.6466 36.786 75.3071 35.9599 75.3071Z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="M105.841 75.1695H94.2851C93.459 75.1695 92.7922 74.509 92.7922 73.6908C92.7922 72.8726 93.459 72.2121 94.2851 72.2121H104.348V68.0618C104.348 67.9534 104.358 67.8351 104.387 67.7266L105.014 65.0354V59.6429L100.993 48.4441C100.993 48.4441 100.496 47.3498 99.5901 47.2118C98.2364 46.995 90.702 46.3936 87.2284 46.1274C86.8303 46.502 86.4322 47.0147 86.4322 47.3794V63.5862C86.4322 64.4044 85.7653 65.0649 84.9392 65.0649C84.1131 65.0649 83.4463 64.4044 83.4463 63.5862V47.3696C83.4463 45.1318 85.6459 43.5545 85.9047 43.3869C86.1834 43.1897 86.5218 43.101 86.8701 43.1306C87.328 43.17 98.2165 43.9981 100.068 44.284C102.158 44.6093 103.392 46.3936 103.8 47.3991L107.931 58.8937C107.99 59.0515 108.02 59.219 108.02 59.3866V65.2029C108.02 65.3114 108.01 65.4297 107.98 65.5381L107.353 68.2294V73.6908C107.353 74.509 106.687 75.1695 105.86 75.1695H105.841Z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="M100.874 59.1303C100.804 59.1303 100.744 59.1303 100.675 59.1205L90.4528 57.7601C89.7163 57.6615 89.1589 57.0305 89.1589 56.2912V48.4934C89.1589 47.6752 89.8258 47.0147 90.6519 47.0147C91.478 47.0147 92.1448 47.6752 92.1448 48.4934V54.9998L101.073 56.1926C101.889 56.301 102.466 57.0503 102.357 57.8586C102.257 58.598 101.61 59.1402 100.884 59.1402L100.874 59.1303Z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="M23.1114 75.2685C23.0119 75.2685 22.9024 75.2685 22.8029 75.2389L19.2795 74.5094C18.8615 74.4207 18.5132 74.1644 18.2942 73.8095C18.0852 73.4448 18.0354 73.011 18.1648 72.6167L20.1255 66.5244C20.1853 66.3272 20.2947 66.1497 20.4341 65.992C20.5535 65.8639 23.3901 62.7684 28.6851 62.7684C32.5071 62.7684 34.7664 64.2471 35.7816 65.1442C36.8466 64.2373 39.2851 62.6797 43.4454 62.6797C47.9342 62.6797 50.94 64.9273 52.0747 65.9526H79.5947L83.5859 62.798C83.8546 62.591 84.183 62.4727 84.5214 62.4727H90.125C94.7034 62.4727 99.7396 67.5496 99.7396 72.1731V73.4349C99.7396 74.2531 99.0727 74.9136 98.2466 74.9136C97.4205 74.9136 96.7537 74.2531 96.7537 73.4349V72.1731C96.7537 68.9199 92.8621 65.4301 90.125 65.4301H85.049L81.0578 68.5847C80.7891 68.7917 80.4606 68.91 80.1222 68.91H51.4675C51.0396 68.91 50.6315 68.7326 50.3528 68.4171C50.2533 68.3087 47.7551 65.6371 43.4454 65.6371C39.1358 65.6371 37.5333 67.5595 37.4637 67.6383C37.175 67.9636 36.7471 68.1805 36.3091 68.1805H35.2342C34.7664 68.1805 34.3185 67.9636 34.0398 67.589C33.9901 67.5299 32.4872 65.7258 28.6851 65.7258C25.4305 65.7258 23.4698 67.2243 22.8626 67.7763L21.519 71.966L23.42 72.3604C24.2262 72.528 24.7437 73.3068 24.5745 74.1053C24.4252 74.8052 23.8082 75.2784 23.1114 75.2784V75.2685Z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="M6.8679 75.4357C4.98678 75.4357 3.63317 75.1498 3.53364 75.1301C2.97628 75.0118 2.53834 74.5879 2.399 74.0457L0.0500949 64.7593C-0.129059 64.0397 0.249155 63.3003 0.945865 63.0243L2.46867 62.4328L2.64783 50.6918C2.64783 50.2876 2.82698 49.8932 3.13552 49.6271L14.4819 39.3845C14.7606 39.138 15.1189 39 15.4872 39H80.6395C81.4656 39 82.1325 39.6605 82.1325 40.4787V62.0188C82.1325 62.837 81.4656 63.4975 80.6395 63.4975C79.8134 63.4975 79.1466 62.837 79.1466 62.0188V41.9574H16.0645L5.62377 51.3818L5.43467 63.4679C5.43467 64.0692 5.05645 64.6016 4.48913 64.8185L3.25496 65.3015L5.0465 72.3698C6.33044 72.5177 8.77888 72.6261 11.0482 71.8079C14.3426 70.6151 15.945 69.0279 15.9649 69.0082C16.5422 68.4265 17.4877 68.4167 18.075 68.9786C18.6622 69.5504 18.6821 70.477 18.1148 71.0587C18.0352 71.1375 16.0645 73.1387 12.0733 74.578C10.2519 75.2385 8.38076 75.4258 6.85795 75.4258L6.8679 75.4357Z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="M22.4344 63.0438C22.4344 63.0438 22.3348 63.0438 22.295 63.0438C21.4789 62.965 20.8717 62.2453 20.9514 61.437L22.5936 44.2345C22.6235 43.8993 22.7728 43.5839 23.0116 43.3374L26.6644 39.6505C27.2417 39.0688 28.1872 39.059 28.7744 39.6307C29.3616 40.2025 29.3716 41.139 28.7943 41.7207L25.5098 45.033L23.9174 61.713C23.8477 62.4819 23.1908 63.0537 22.4344 63.0537V63.0438Z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="M6.02194 71.0588H3.18533C2.35923 71.0588 1.69238 70.3983 1.69238 69.5801C1.69238 68.7619 2.35923 68.1014 3.18533 68.1014H5.30532L17.0996 53.5212L18.9409 40.7648C19.0604 39.9564 19.8168 39.3945 20.6329 39.5128C21.4491 39.6311 22.0164 40.3803 21.897 41.1887L19.9959 54.3493C19.9561 54.6155 19.8467 54.8619 19.6874 55.0689L7.18644 70.5068C6.90775 70.8518 6.46982 71.0588 6.02194 71.0588Z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="M18.7411 52.5151H4.46854C3.64244 52.5151 2.97559 51.8546 2.97559 51.0363C2.97559 50.2181 3.64244 49.5576 4.46854 49.5576H18.7411C19.5672 49.5576 20.2341 50.2181 20.2341 51.0363C20.2341 51.8546 19.5672 52.5151 18.7411 52.5151Z"
          fill={color ? color : 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_6791_15775">
          <rect width="100%" height="100%" fill="white" transform="translate(0 39)" />
        </clipPath>
      </defs>
    </svg>
  );
};
