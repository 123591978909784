import SE from './SE.json';
import NO from './NO.json';
import FI from './FI.json';
import DK from './DK.json';
import PL from './PL.json';

type Holiday = {
  date: string;
  holidayName: string;
};

export const holidaysPerCountry: Record<string, Holiday[]> = {
  SE,
  NO,
  FI,
  DK,
  PL,
};
