export const getWeekByLanguage = (locale: string) => {
  switch (locale) {
    case 'sv':
      return 'Vecka';
    case 'nb':
      return 'Uke';
    case 'da':
      return 'Uge';
    case 'fi':
      return 'Viikko';
    default:
      return 'Week';
  }
};
