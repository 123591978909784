import { type ReactNode } from 'react';
import { Heading, Paragraph, spacing } from 'component-library';
import { MediaCardWrapper, Media, Content, ButtonWrapper } from './styles';

export type MediaCardProps = {
  boxed: boolean;
  image: string;
  imageDirection: 'left' | 'right';
  backgroundPosition?: string;
  heading?: string;
  subheading?: string;
  body?: string | ReactNode;
  button?: ReactNode;
  mt?: string;
  mb?: string;
  fitToContent?: boolean;
};

export const MediaCard = ({
  heading,
  subheading,
  body,
  button,
  boxed,
  image,
  imageDirection,
  backgroundPosition = 'center center',
  mt,
  mb,
  fitToContent,
}: MediaCardProps) => {
  return (
    <MediaCardWrapper mt={mt} mb={mb} boxed={boxed} imageDirection={imageDirection}>
      <Media
        boxed={boxed}
        image={image}
        imageDirection={imageDirection}
        backgroundPosition={backgroundPosition}
        fitToContent={fitToContent}
      />
      <Content boxed={boxed} imageDirection={imageDirection}>
        {heading && (
          <Heading as="h1" variant="large" mb={spacing.small}>
            {heading}
          </Heading>
        )}
        {subheading && (
          <Heading as="h2" variant="small" mb={spacing.small}>
            {subheading}
          </Heading>
        )}
        {body && <Paragraph variant="large">{body}</Paragraph>}
        {button && <ButtonWrapper>{button}</ButtonWrapper>}
      </Content>
    </MediaCardWrapper>
  );
};

MediaCard.displayName = 'MediaCard';
