// 2022_waste_02-01
import { type PictogramIconProps } from './types';

export const Waste = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5171 79.1241C32.7331 70.8568 41.6498 65.412 51.753 65.412C60.3993 65.412 68.1792 69.3769 73.4885 75.7449C76.305 74.3278 79.5014 73.5107 82.8641 73.5107C94.4381 73.5107 103.719 83.0268 104.559 94.9028C104.59 95.3321 104.44 95.7548 104.147 96.0697C103.854 96.3845 103.443 96.5634 103.012 96.5634H7.70359C6.84699 96.5634 6.15259 95.869 6.15259 95.0124C6.15259 85.7569 13.2736 78.054 22.1233 78.054C24.0723 78.054 25.8581 78.4756 27.5171 79.1241ZM51.753 68.514C42.3845 68.514 34.0847 73.8 29.4956 81.8543C29.0941 82.5591 28.2172 82.8354 27.4841 82.4881C25.7484 81.666 24.0027 81.156 22.1233 81.156C15.6426 81.156 10.0626 86.477 9.33485 93.4614H101.283C99.851 83.8345 92.0995 76.6127 82.8641 76.6127C79.5975 76.6127 76.5086 77.5201 73.8707 79.0665C73.1939 79.4632 72.3273 79.2921 71.8522 78.6678C67.0881 72.4091 59.8285 68.514 51.753 68.514Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.258 32.0258C89.8438 32.6116 89.8438 33.5613 89.258 34.1471L64.2704 59.1347C63.6846 59.7205 62.7348 59.7205 62.1491 59.1347C61.5633 58.549 61.5633 57.5992 62.1491 57.0134L87.1367 32.0258C87.7225 31.44 88.6722 31.44 89.258 32.0258Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.1233 38.105C62.5212 38.105 62.9027 38.263 63.184 38.5443L82.7395 58.0999C83.3253 58.6857 83.3253 59.6354 82.7395 60.2212L81.6789 59.1605C82.7395 60.2212 82.7396 60.2211 82.7395 60.2212L82.7381 60.2227L69.505 73.4558C68.9192 74.0416 67.9694 74.0416 67.3837 73.4558C66.7979 72.87 66.7979 71.9202 67.3837 71.3344L79.5576 59.1605L62.1233 41.7263L45.9988 57.8508C43.806 60.0436 43.4031 63.6752 44.9458 67.4866C45.2567 68.2545 44.8861 69.129 44.1182 69.4398C43.3503 69.7507 42.4758 69.3801 42.165 68.6122C40.3497 64.1273 40.5394 59.0676 43.8775 55.7295L61.0627 38.5443C61.344 38.263 61.7255 38.105 62.1233 38.105Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.1269 15.3889C94.5259 15.3899 94.9082 15.5498 95.189 15.8334L105.362 26.105C105.943 26.6916 105.941 27.6374 105.357 28.2212L104.296 27.1605C105.357 28.2212 105.357 28.2211 105.357 28.2212L105.089 28.4891C104.918 28.6595 104.674 28.9028 104.382 29.1948C103.796 29.7787 103.014 30.5574 102.229 31.3365C101.444 32.1149 100.654 32.896 100.053 33.4836C99.7526 33.7765 99.4948 34.0261 99.3067 34.2043C99.2141 34.292 99.1295 34.3709 99.0618 34.4313C99.0327 34.4574 98.9929 34.4924 98.9505 34.5269C97.5165 35.9018 95.5435 36.3446 93.6212 36.0784C91.6602 35.8069 89.6645 34.7984 88.0255 33.1595C86.4299 31.5638 85.429 29.5692 85.1449 27.6104C84.8618 25.658 85.2849 23.6059 86.7416 22.1492L93.0626 15.8283C93.3448 15.5461 93.7278 15.388 94.1269 15.3889ZM102.179 27.1545L94.1181 19.0154L88.8629 24.2706C88.2455 24.888 87.9279 25.8976 88.1139 27.1798C88.2989 28.4556 88.9771 29.8684 90.1468 31.0381C91.3721 32.2634 92.7837 32.9339 94.0327 33.1068C95.2819 33.2798 96.2808 32.9557 96.9144 32.3221C96.9661 32.2704 97.0212 32.2228 97.0791 32.1797C97.1147 32.1474 97.1688 32.0971 97.2434 32.0265C97.4144 31.8644 97.659 31.6278 97.9563 31.3375C98.5492 30.7584 99.3328 29.9839 100.116 29.2068C100.862 28.4669 101.605 27.7268 102.179 27.1545ZM97.4601 31.9739C97.4189 31.989 97.3784 32.0058 97.3387 32.0244C97.3642 32.0122 97.4056 31.9936 97.4601 31.9739Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  );
};
