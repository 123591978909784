import { LoadingWrapper } from '../../../../Data/Table/style';
import { SkeletonLoader } from '../../../../Feedback/SkeletonLoader';
import { spacing } from '../../../../../themes';

export const DropdownSkeletonLoader = () => {
  return (
    <LoadingWrapper>
      <SkeletonLoader height={spacing.medium} />
      <SkeletonLoader height={spacing.medium} />
      <SkeletonLoader height={spacing.medium} />
    </LoadingWrapper>
  );
};
