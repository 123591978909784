import { useTranslation } from 'react-i18next';
import { Button, Spinner } from 'component-library';
import {
  LabelAndButtonWrapper,
  LoadMoreButtonWrapper,
  PageLabel,
  TableFooterWrapper,
} from './styles';

type TableFooterProps = {
  hasMore: boolean;
  loadMore: () => void;
  pageLabel: string;
  noLines: boolean;
  hideTotal?: boolean;
  loadMoreIsLoading?: boolean;
};

const TableFooter = ({
  hasMore,
  loadMore,
  pageLabel,
  noLines,
  hideTotal,
  loadMoreIsLoading,
}: TableFooterProps) => {
  const { t } = useTranslation('', { keyPrefix: 'component.table.footer' });

  if (!hasMore) {
    return null;
  }

  if (loadMoreIsLoading) {
    return (
      <TableFooterWrapper noLines={noLines}>
        <Spinner size="xlarge" />
      </TableFooterWrapper>
    );
  }

  return (
    <TableFooterWrapper noLines={noLines}>
      <LabelAndButtonWrapper>
        {!hideTotal && <PageLabel>{pageLabel}</PageLabel>}
        <LoadMoreButtonWrapper>
          <Button
            variant="secondary"
            onClick={() => {
              loadMore();
            }}
          >
            {t('load.more')}
          </Button>
        </LoadMoreButtonWrapper>
      </LabelAndButtonWrapper>
    </TableFooterWrapper>
  );
};

export default TableFooter;
