import { type IconProps } from '../../types';
import { IconWrapper } from './style';

export const ServiceArrow = ({ width = '24px', height = '24px', cursor }: IconProps) => {
  return (
    <IconWrapper width={width} height={height} cursor={cursor}>
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.666992" width="32" height="32" rx="16" fill="#327EA5" />
        <path
          d="M10.667 16H22.667M22.667 16L16.667 10M22.667 16L16.667 22"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconWrapper>
  );
};
