import { useEffect, useState } from 'react';

export const useSegmentedButtonsActiveIndex = (prop: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const [selectedValue, _setSelectedValue] = useState<string>(prop.value);

  const setSelectedValue = (value: string) => {
    _setSelectedValue(value);
    prop.onChange(value);
  };

  useEffect(() => {
    _setSelectedValue(prop.value);
  }, [prop.value]);

  return {
    selectedValue,
    setSelectedValue,
    onChange: prop.onChange,
  };
};
