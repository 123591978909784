import { type PictogramIconProps } from './types';

// tools_05
export const MagnifyingGlass = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2628 21.1592C32.8118 9.61281 51.5375 9.6136 63.0855 21.1616C74.6342 32.7103 74.6342 51.4378 63.0855 62.9866C51.5367 74.5353 32.8092 74.5353 21.2604 62.9866C9.71517 51.4413 9.70742 32.6131 21.2628 21.1592ZM60.9641 23.2829C50.587 12.9057 33.7589 12.9057 23.3818 23.2829L23.377 23.2877L23.3769 23.2877C13.0089 33.5624 13.0019 50.4854 23.3818 60.8653C33.7589 71.2424 50.587 71.2424 60.9641 60.8653C71.3413 50.4881 71.3413 33.6601 60.9641 23.2829Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.1725 21.6866C30.9676 21.6866 21.8842 30.7701 21.8842 41.975C21.8842 53.18 30.9676 62.2634 42.1725 62.2634C53.3775 62.2634 62.4609 53.18 62.4609 41.975C62.4609 30.7701 53.3775 21.6866 42.1725 21.6866ZM19.0042 41.975C19.0042 29.1795 29.377 18.8066 42.1725 18.8066C54.9681 18.8066 65.3409 29.1795 65.3409 41.975C65.3409 54.7706 54.9681 65.1434 42.1725 65.1434C29.377 65.1434 19.0042 54.7706 19.0042 41.975Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.9292 30.7988C31.3198 31.1893 31.3198 31.8224 30.9292 32.213C25.4471 37.6951 25.2403 46.1468 30.2379 51.1444C30.6284 51.535 30.6284 52.1681 30.2379 52.5586C29.8473 52.9492 29.2142 52.9492 28.8237 52.5586C22.9571 46.6921 23.3428 36.971 29.515 30.7988C29.9055 30.4082 30.5387 30.4082 30.9292 30.7988Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.2249 57.209C64.8117 56.6242 65.7615 56.6259 66.3462 57.2127L94.4944 85.4596C95.0784 86.0457 95.0776 86.994 94.4925 87.5791L87.6777 94.3939C87.3961 94.6755 87.014 94.8336 86.6157 94.8332C86.2174 94.8329 85.8357 94.6741 85.5545 94.392L57.3076 66.0463C56.7228 65.4595 56.7245 64.5098 57.3113 63.925C57.8981 63.3403 58.8479 63.3419 59.4326 63.9287L86.6189 91.21L91.3124 86.5165L64.2212 59.3303C63.6364 58.7435 63.6381 57.7937 64.2249 57.209Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.4935 88.0801C96.8913 88.0801 97.2729 88.2381 97.5542 88.5194C100.017 90.9817 100.017 94.9932 97.5542 97.4556C95.0919 99.9179 91.0804 99.9179 88.6181 97.4556C88.0323 96.8698 88.0323 95.92 88.6181 95.3342L95.4329 88.5194C95.7142 88.2381 96.0957 88.0801 96.4935 88.0801ZM96.2385 91.9564L92.055 96.1399C93.2056 96.5125 94.5232 96.2439 95.4329 95.3342C96.3426 94.4245 96.6111 93.1069 96.2385 91.9564Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  );
};
