import { useContext } from 'react';
import { DropdownContexts } from '../../../../context/DropdownContext';
import { DropdownErrorMessageStyled } from './styles';

export const DropdownErrorMessage = () => {
  const { errorMessage, error, open } = useContext(DropdownContexts.useDropdown);

  if (!error || open || !errorMessage) return null;

  return <DropdownErrorMessageStyled>{errorMessage}</DropdownErrorMessageStyled>;
};
