import { spacing } from '../../../themes';
import { Heading } from '../../Typography';
import { Area, Divider, PageHeaderWrapper, WelcomeText } from './styles';

type PageHeaderProps = {
  areaTitle?: string;
  pageTitle: string;
  text?: string;
  center?: boolean;
  divider?: boolean;
};

export const PageHeader = ({
  areaTitle,
  pageTitle,
  text,
  center = true,
  divider = true,
}: PageHeaderProps) => {
  return (
    <PageHeaderWrapper center={center}>
      {areaTitle && (
        <>
          <Area>{areaTitle}</Area>
          {divider && <Divider />}
        </>
      )}
      <Heading as="h1" variant="xLarge" mb={spacing.xsmall}>
        {pageTitle}
      </Heading>
      {text && <WelcomeText>{text}</WelcomeText>}
    </PageHeaderWrapper>
  );
};
