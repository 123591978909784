import { type ListDropdownItem } from '@stenarecycling/customer-portal-types';
import { type LocationListItemsType } from '../../components/DropdownList/Lists/LocationPicker/types';

export const toggleSingleSelectValue = (selectedItems: string[], inputValue: string) => {
  return [inputValue];
};

export const toggleMultiSelectValue = (selectedItems: string[], toggleValues: string[]) => {
  const selectedValuesSet = new Set(selectedItems);
  const result = new Set(selectedItems);

  toggleValues.forEach((value) => {
    if (selectedValuesSet.has(value)) {
      result.delete(value);
    } else {
      result.add(value);
    }
  });

  return Array.from(result);
};

export const getItemsValues = (items: ListDropdownItem[]): string[] => {
  const result: string[] = [];

  const traverseItems = (items: ListDropdownItem[]) => {
    for (const item of items) {
      if (item.type === 'value' || (item.children.length === 0 && item.value)) {
        result.push(item.value);
      }

      if (item.children.length) {
        traverseItems(item.children);
      }
    }
  };

  traverseItems(items);

  return result;
};

export const getInactiveItemsValues = (items: ListDropdownItem[]): string[] => {
  const result: string[] = [];

  const traverseItems = (items: LocationListItemsType[]) => {
    for (const item of items) {
      if (!item.meta?.isActive && item.meta?.shouldExcludeInactiveValues) {
        result.push(item.value);
      }

      if (item.children.length) {
        traverseItems(item.children);
      }
    }
  };

  traverseItems(items);

  return result;
};

export const getFirstFullySelectedGroup = (
  items: ListDropdownItem[],
  selectedValues: string[],
): ListDropdownItem | null => {
  const selectedValuesSet = new Set(selectedValues);

  for (const item of items) {
    if (item.type === 'value' && item.children.length) {
      const allValuesInGroup = getItemsValues([item]);

      const hasAllChildrenSelected = allValuesInGroup.every((value) =>
        selectedValuesSet.has(value),
      );

      if (hasAllChildrenSelected) return item;
    }
  }

  return null;
};

export const getListItemsFlat = <T extends ListDropdownItem[]>(
  items: ListDropdownItem[],
  selectedValues: string[],
  returnAllItems?: boolean,
): T | ListDropdownItem[] => {
  if (!items) return [];

  const selectedItemsMap = new Map<string, ListDropdownItem>();
  const selectedValuesSet = new Set(selectedValues);

  const flattenItems = (items: ListDropdownItem[]): void => {
    for (const item of items) {
      const itemValue = item.value;

      if (item.children.length) {
        flattenItems(item.children);
      }

      if (!selectedValuesSet.has(itemValue) && !returnAllItems) continue;

      selectedItemsMap.set(itemValue, item);
    }
  };

  flattenItems(items);

  const selectedItems = Array.from(selectedItemsMap.values());

  selectedItems.sort((a, b) => selectedValues.indexOf(a.value) - selectedValues.indexOf(b.value));

  return selectedItems;
};
