import { type IconProps } from '../../types';
import { IconWrapper } from './style';

export const Cart = ({ color, rotation, width = '24px', height = '24px', cursor }: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 3 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ? rotation : 0})`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.75949C2 2.34004 2.34649 2 2.77391 2H3.28098C4.54795 2 5.66142 2.82424 6.00948 4.01976L6.60078 6.05074H20.7096C21.5308 6.05074 22.1429 6.7938 21.9709 7.58179L20.6442 13.6577C20.5167 14.2414 19.9911 14.6583 19.3829 14.6583H9.10679L9.28171 15.2591C9.43992 15.8026 9.94604 16.1772 10.5219 16.1772H18.2522C18.6796 16.1772 19.0261 16.5172 19.0261 16.9367C19.0261 17.3562 18.6796 17.6962 18.2522 17.6962H10.5219C9.25497 17.6962 8.1415 16.872 7.79343 15.6764L4.52121 4.43706C4.363 3.89364 3.85688 3.51899 3.28098 3.51899H2.77391C2.34649 3.51899 2 3.17895 2 2.75949ZM8.66455 13.1393H19.1744L20.3905 7.56972H7.04302L8.66455 13.1393ZM9.54783 18.75C8.69299 18.75 8 19.4301 8 20.269C8 21.1079 8.69299 21.788 9.54783 21.788C10.4027 21.788 11.0957 21.1079 11.0957 20.269C11.0957 19.4301 10.4027 18.75 9.54783 18.75ZM16.5478 18.75C15.693 18.75 15 19.4301 15 20.269C15 21.1079 15.693 21.788 16.5478 21.788C17.4027 21.788 18.0957 21.1079 18.0957 20.269C18.0957 19.4301 17.4027 18.75 16.5478 18.75Z"
        fill={color ? color : 'currentColor'}
        transform="translate(2, 4)"
      />
    </svg>
  </IconWrapper>
);
