import { useContext, useCallback } from 'react';
import { type ListDropdownItem } from '@stenarecycling/customer-portal-types';
import { DropdownContexts } from '../../../context/DropdownContext';
import { Description, ListWrapper, SimpleListItemWrapper } from '../style';
import { ListItemSelectSimple } from '../components/ListItemSelectSimple';

export const SimpleSelectList = () => {
  const { items } = useContext(DropdownContexts.useItems);
  const { searchActive, searchResults } = useContext(DropdownContexts.useSearch);

  const itemsToRender = searchActive ? searchResults : items;

  const getDesc = useCallback((item: ListDropdownItem) => {
    if (item.meta?.description) {
      if (Array.isArray(item.meta?.description)) {
        return item.meta?.description.map((desc: string) => (
          <Description key={desc}>{desc}</Description>
        ));
      }

      return <Description>{item.meta?.description}</Description>;
    }

    return null;
  }, []);

  return (
    <ListWrapper>
      {itemsToRender.map((item, index) => (
        <ListItemSelectSimple key={item.value + item.label + index} item={item}>
          <SimpleListItemWrapper>
            <span>{item.label}</span>
            {getDesc(item)}
          </SimpleListItemWrapper>
        </ListItemSelectSimple>
      ))}
    </ListWrapper>
  );
};
