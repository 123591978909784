import { type ReactNode } from 'react';
import { Heading } from '../../Typography/Heading';
import { spacing } from '../../../themes/stena-recycling/spacing';
import { Paragraph } from '../..//Typography/Paragraph';
import { ServiceArrow } from '../../Icons/ServiceArrow';
import { IconLinkWrapper, LinkCardTextWrapper, LinkCardWrapper } from './styles';

type LinkCardProps = {
  children: ReactNode;
  href?: string;
  title: string;
  vertical?: boolean;
  onClick?: () => void;
};

export const LinkCard = ({ children, title, href, onClick, vertical }: LinkCardProps) => {
  const linkClicked = () => {
    if (onClick) {
      onClick();
    } else if (href) {
      location.href = href;
    }
  };

  return (
    <LinkCardWrapper
      vertical={vertical}
      onClick={() => {
        linkClicked();
      }}
    >
      <LinkCardTextWrapper vertical={vertical}>
        <Heading as="h3" variant="sCaps" mb={vertical ? '0' : spacing.xxsmall}>
          {title}
        </Heading>
        {!vertical && <Paragraph variant="large">{children}</Paragraph>}
      </LinkCardTextWrapper>
      <IconLinkWrapper>
        <ServiceArrow width={spacing.mediumLow} height={spacing.mediumLow} />
      </IconLinkWrapper>
    </LinkCardWrapper>
  );
};
