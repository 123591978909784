import { useContext } from 'react';
import { type ListDropdownItem } from '@stenarecycling/customer-portal-types';
import { useTranslation } from 'react-i18next';
import { DropdownContexts } from '../../../../../../context/DropdownContext';
import { ListItemSelectGroup } from '../../../../components/ListItemSelectGroup/ListItemSelectGroup';
import { ListWrapper } from '../../../../style';
import { ChildCount, ChildWrapper, Description, GroupedListItemWrapper } from './style';

export type ListItemsGroups = ListDropdownItem<{ description: string; childCount: number }>;
/**
 * Recursive component that renders a group list item and its children
 * @param item
 * @param nested
 * @constructor
 */
export const GroupList = ({
  item,
  nestedIndex = 0,
}: {
  item: ListItemsGroups;
  nestedIndex?: number;
}) => {
  const { isChildOpen } = useContext(DropdownContexts.useDropdown);
  const { searchActive } = useContext(DropdownContexts.useSearch);

  const { t } = useTranslation();

  const shouldShowChildren = isChildOpen(item.value) || searchActive;
  const hasDescription = !!item.meta?.description.length;
  const childCount = item.meta?.childCount ?? 0;
  const hasChildCount = childCount > 0;

  return (
    <GroupedListItemWrapper nestedIndex={nestedIndex}>
      <ListWrapper>
        <ListItemSelectGroup item={item} nestedIndex={nestedIndex}>
          <ChildWrapper>
            {hasDescription && <Description>{item.meta?.description}</Description>}

            {hasChildCount && (
              <ChildCount>{` (${childCount + 1} ${t(
                'components.dropdown.locations',
              )})`}</ChildCount>
            )}
          </ChildWrapper>
        </ListItemSelectGroup>

        {shouldShowChildren &&
          item.children.map((item) => (
            <GroupList key={item.value + item.label} item={item} nestedIndex={nestedIndex + 1} />
          ))}
      </ListWrapper>
    </GroupedListItemWrapper>
  );
};
