import { useContext } from 'react';

import { type ListDropdownItem } from '@stenarecycling/customer-portal-types';
import { DropdownContexts } from '../../../../context/DropdownContext';
import { Checkbox } from '../../../../../Checkbox';
import { Chevron } from '../../../../../../Icons';
import { colors } from '../../../../../../../themes';
import {
  ChevronWrapper,
  GroupedListContent,
  GroupedListWrapper,
  Label,
  StyledCheckboxWrapper,
} from './style';

export const ListItemSelectGroup = ({
  item,
  children,
  nestedIndex = 0,
}: {
  item: ListDropdownItem;
  children?: React.ReactNode;
  nestedIndex?: number;
}) => {
  const { isChildOpen, toggleOpenChild, toggleOpen } = useContext(DropdownContexts.useDropdown);
  const { toggleItem, isItemSelected } = useContext(DropdownContexts.useValues);
  const { searchActive } = useContext(DropdownContexts.useSearch);
  const { multiSelect } = useContext(DropdownContexts.props);
  const handleClick = () => {
    toggleItem(item);
  };

  const checked = isItemSelected(item);
  const semiChecked = checked === 'partial';
  const shouldToggleChildren = item.children.length > 0;
  const isOpen = isChildOpen(item.value);
  const showAsOpen = isChildOpen(item.value) || searchActive;

  const handleGroupClick = () => {
    if (shouldToggleChildren) {
      toggleItem(item);

      // Open children on selection for multi select
      if (!isOpen && !checked && multiSelect) toggleOpenChild(item.value);
    } else {
      toggleItem(item);
    }

    // Close dropdown on selection for single select
    if (checked !== true && !multiSelect) toggleOpen();
  };

  const handleChevronClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    toggleOpenChild(item.value);
  };

  return (
    <GroupedListWrapper onClick={handleGroupClick} nestedIndex={nestedIndex}>
      <StyledCheckboxWrapper>
        <Checkbox
          onChange={handleClick}
          semiChecked={semiChecked}
          checked={semiChecked ? false : checked}
          color={colors.primary.grey}
        />
      </StyledCheckboxWrapper>

      <GroupedListContent>
        <Label showBold={item.type === 'group'}>{item.label}</Label>

        {children}
      </GroupedListContent>

      {shouldToggleChildren && (
        <ChevronWrapper onClick={handleChevronClick}>
          <Chevron height="24px" width="24px" rotation={showAsOpen ? 180 : 0} />
        </ChevronWrapper>
      )}
    </GroupedListWrapper>
  );
};
