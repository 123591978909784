import { type ListDropdownItem } from '@stenarecycling/customer-portal-types';

const itemSearchFilter = (item: ListDropdownItem, searchLower: string) => {
  const description = item.meta?.description ?? '';

  const itemString = `${item.label}${item.value}${description}`.toLowerCase();

  return itemString.includes(searchLower);
};

const itemIsInSearch = (item: ListDropdownItem, searchLower: string) => {
  if (itemSearchFilter(item, searchLower)) {
    return { inSearch: true };
  }

  for (const child of item.children ?? []) {
    if (itemSearchFilter(child, searchLower)) {
      return {
        childrenInSearch: true,
      };
    }
  }

  return { inSearch: false, childrenInSearch: false };
};

export const filterItems = (items: ListDropdownItem[], searchLower: string): ListDropdownItem[] => {
  const filteredItems: ListDropdownItem[] = [];

  for (const item of items) {
    const { inSearch, childrenInSearch } = itemIsInSearch(item, searchLower);

    if (inSearch) {
      filteredItems.push({ ...item, children: item.children });
    } else if (childrenInSearch) {
      const filteredChildren = filterItems(item.children ?? [], searchLower);

      filteredItems.push({ ...item, children: filteredChildren });
    } else {
      const filteredChildren = filterItems(item.children ?? [], searchLower);

      if (filteredChildren.length > 0) {
        filteredItems.push({ ...item, children: filteredChildren });
      }
    }
  }

  return filteredItems;
};

// Flatten items to one level
const flattenItems = (items: ListDropdownItem[]): ListDropdownItem[] => {
  const flatItems: ListDropdownItem[] = [];

  for (const item of items) {
    if (item.type === 'value') flatItems.push(item);

    if (item.children.length > 0) {
      // eslint-disable-next-line prefer-spread -- Bad performance
      flatItems.push.apply(flatItems, flattenItems(item.children));
    }
  }

  return flatItems;
};

export const locationSearchItemsFlat = async (items: ListDropdownItem[], search: string) => {
  const flatItems = flattenItems(items);
  const flatNoChilds = flatItems.map((item) => ({
    ...item,
    children: [],
    meta: { ...item.meta, childCount: 0 },
  }));

  return filterItems(flatNoChilds, search.toLowerCase());
};

export const locationSearchItems = async (
  inputItems: ListDropdownItem[],
  search: string,
): Promise<ListDropdownItem[]> => {
  const searchLower = search.toLowerCase();
  const items = [...inputItems];
  const filteredItems = filterItems(items, searchLower);

  return filteredItems;
};
