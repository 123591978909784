import { colors } from '../../../themes';
import { type PictogramCircleBackgroundProps } from './types';

export const BackgroundCircle = ({ color, light }: PictogramCircleBackgroundProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 220 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="110"
        cy="110"
        r="110"
        fill={light ? colors.base.white : color ? color : 'currentColor'}
      />
      <circle
        cx="102"
        cy="102"
        r="100"
        stroke={light ? (color ? color : 'currentColor') : colors.base.white}
        strokeWidth="4"
        transform="translate(8, 8)"
      />
    </svg>
  );
};
