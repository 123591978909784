import { useCallback, useEffect, useMemo, useState } from 'react';
import { breakpoint } from '../../../themes/stena-recycling/breakpoint';

type UseModalProps = {
  onClose?: () => void;
  show: boolean;
  closeOnClickOutside?: boolean;
};

const useModal = ({ onClose, show, closeOnClickOutside }: UseModalProps) => {
  const [initialOverflowY, setInitialOverflowY] = useState('');

  document.documentElement.style.setProperty('--active-modal-padding', '0px');

  useEffect(() => {
    setInitialOverflowY(document.body.style.overflowY);
  }, []);

  const bodyClick = useCallback(
    (e: MouseEvent) => {
      if (!closeOnClickOutside) {
        return;
      }
      const target = e.target as HTMLElement;

      if (target.id === 'wrapper') {
        onClose && onClose();
      }
    },
    [onClose, closeOnClickOutside],
  );

  const bodyKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        onClose && onClose();
      }
    },
    [onClose],
  );

  const isFullscreenModal = useMemo(() => window.innerWidth < breakpoint.medium, []);

  const resetBody = useCallback(() => {
    if (!isFullscreenModal) {
      document.body.style.overflowY = initialOverflowY;
      document.body.style.setProperty('--active-modal-padding', '0px');
    }
    document.body.removeEventListener('click', bodyClick);
    document.body.removeEventListener('keydown', bodyKeydown);
  }, [initialOverflowY, bodyClick, bodyKeydown, isFullscreenModal]);

  useEffect(() => {
    if (show && !isFullscreenModal) {
      const isScrollbarVisible =
        document.body.offsetHeight > window.innerHeight || document.body.className === 'one-spa';

      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      const paddingRight = isScrollbarVisible ? `${scrollbarWidth}px` : '0px';

      document.body.style.setProperty('--active-modal-padding', paddingRight);
      document.body.style.overflowY = 'hidden';
      document.body.addEventListener('click', bodyClick);
      document.body.addEventListener('keydown', bodyKeydown);
    }

    return resetBody;
  }, [show, bodyClick, bodyKeydown, resetBody, isFullscreenModal]);

  return { resetBody };
};

export default useModal;
