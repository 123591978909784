import { useCallback, useEffect, useState } from 'react';
import { ToggleContainer, Label, LabelWrapper, StyledToggle, Switch } from './style';

type ToggleProps = {
  checked?: boolean;
  label?: string;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
};

export const Toggle = ({ checked = false, label, disabled, onChange }: ToggleProps) => {
  const [isChecked, setIsChecked] = useState(checked ? checked : false);

  const toggleChecked = useCallback(() => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  }, [isChecked, onChange]);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <ToggleContainer>
      <LabelWrapper>{label && <Label>{label}</Label>}</LabelWrapper>
      <StyledToggle onClick={toggleChecked} disabled={disabled} checked={isChecked}>
        <Switch checked={isChecked} />
      </StyledToggle>
    </ToggleContainer>
  );
};
