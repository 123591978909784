export const LocationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99984 0.833374C6.45119 0.833374 4.96694 1.45454 3.87336 2.55865C2.77993 3.6626 2.1665 5.15887 2.1665 6.71804C2.1665 9.50768 3.54771 11.6001 4.89969 12.9786C6.25028 14.3557 7.60749 15.059 7.6615 15.0863L7.66261 15.0868C7.76717 15.1393 7.88261 15.1667 7.99984 15.1667C8.11706 15.1667 8.2325 15.1393 8.33707 15.0868L8.33818 15.0863C8.39192 15.0591 9.74922 14.3568 11.0999 12.9801C12.452 11.602 13.8332 9.50954 13.8332 6.71804C13.8332 5.15887 13.2197 3.6626 12.1263 2.55865C11.0327 1.45454 9.54849 0.833374 7.99984 0.833374ZM4.58384 3.26236C5.49065 2.34683 6.71951 1.83337 7.99984 1.83337C9.28016 1.83337 10.509 2.34683 11.4158 3.26236C12.3228 4.17806 12.8332 5.42101 12.8332 6.71804C12.8332 9.15609 11.6308 11.0111 10.3861 12.2798C9.36239 13.3232 8.33412 13.944 7.99991 14.1322C7.66586 13.9439 6.63749 13.3224 5.61363 12.2784C4.36879 11.0092 3.1665 9.1541 3.1665 6.71804C3.1665 5.42101 3.67688 4.17806 4.58384 3.26236ZM7.07389 5.28092C7.34797 5.09779 7.6702 5.00004 7.99984 5.00004C8.44186 5.00004 8.86579 5.17564 9.17835 5.4882C9.49091 5.80076 9.6665 6.22468 9.6665 6.66671C9.6665 6.99634 9.56876 7.31857 9.38562 7.59266C9.20248 7.86674 8.94219 8.08036 8.63764 8.2065C8.3331 8.33265 7.99799 8.36566 7.67469 8.30135C7.35139 8.23704 7.05441 8.0783 6.82133 7.84522C6.58824 7.61213 6.42951 7.31516 6.3652 6.99186C6.30089 6.66856 6.33389 6.33344 6.46004 6.0289C6.58619 5.72436 6.79981 5.46406 7.07389 5.28092ZM7.99984 4.00004C7.47242 4.00004 6.95685 4.15644 6.51832 4.44945C6.07979 4.74247 5.73799 5.15895 5.53616 5.64622C5.33433 6.13349 5.28152 6.66966 5.38441 7.18695C5.4873 7.70423 5.74128 8.17938 6.11422 8.55232C6.48716 8.92526 6.96231 9.17924 7.4796 9.28213C7.99688 9.38503 8.53306 9.33222 9.02033 9.13038C9.5076 8.92855 9.92407 8.58676 10.2171 8.14823C10.5101 7.70969 10.6665 7.19412 10.6665 6.66671C10.6665 5.95946 10.3856 5.28119 9.88546 4.78109C9.38536 4.28099 8.70708 4.00004 7.99984 4.00004Z"
        fill="#657B6C"
      />
    </svg>
  );
};
