import { useContext } from 'react';
import { DropdownContexts } from '../../../../context/DropdownContext';
import { ListItemSelectAll } from '../../components/ListItemSelectAll';
import { Divider, ListWrapper } from '../../style';
import { GroupList } from './components/GroupList/GroupList';

export const MultiSelectList = () => {
  const { items } = useContext(DropdownContexts.useItems);
  const { searchResults, searchActive } = useContext(DropdownContexts.useSearch);

  const itemsToRender = searchActive ? searchResults : items;

  return (
    <ListWrapper>
      <ListItemSelectAll />

      <Divider />

      {itemsToRender.map((item, index) => (
        <GroupList key={index} item={item} />
      ))}
    </ListWrapper>
  );
};
