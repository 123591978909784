import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { type ListDropdownItem } from '@stenarecycling/customer-portal-types';
import { DropdownContexts } from '../../../../../../context/DropdownContext';
import { Searchfield } from '../../../../../../../Text/Searchfield';
import { useInactiveState } from '../../../../../../../LocationPicker/hooks/useLocationPicker/useInactiveState';
import {
  DropdownSearchWrapper,
  StyledEmptySearchMessage,
  StyledSearchTitle,
  StyledWrapper,
} from './styles';

export const LocationPickerSearch = () => {
  const { searchPlaceholder, items } = useContext(DropdownContexts.props);

  const { onSearch, resetSearch, searchText, searchActive, searchResultsEmpty } = useContext(
    DropdownContexts.useSearch,
  );

  const { hideInactiveLocations } = useInactiveState();

  const { t } = useTranslation();

  const handleSearch = (val: string) => {
    void onSearch(val.trim());
  };

  if (items.length === 0) {
    return (
      <StyledWrapper>
        <StyledEmptySearchMessage>{t('components.dropdown.list.empty')}</StyledEmptySearchMessage>
      </StyledWrapper>
    );
  }

  const itemLength = countItems(items, !hideInactiveLocations);

  if (itemLength < 10) {
    return null;
  }

  return (
    <StyledWrapper>
      <DropdownSearchWrapper>
        <Searchfield
          defaultValue={searchText}
          onChange={(value) => {
            handleSearch(value);
          }}
          onClear={resetSearch}
          placeholder={searchPlaceholder}
        />
      </DropdownSearchWrapper>

      {searchActive && (
        <StyledSearchTitle>{t('components.dropdown.search.result.title')}</StyledSearchTitle>
      )}

      {searchActive && searchResultsEmpty && (
        <StyledEmptySearchMessage>
          {t('components.dropdown.search.location.result.empty', { searchQuery: searchText })}
        </StyledEmptySearchMessage>
      )}
    </StyledWrapper>
  );
};

const countItems = (items: ListDropdownItem[], countInactive: boolean): number => {
  return items.reduce((acc, item) => {
    if (item.children && item.children.length > 0) {
      acc = acc + countItems(item.children, countInactive);
    }

    if (!item.meta?.isActive && !countInactive) {
      return acc;
    }

    return acc + 1;
  }, 0);
};
