import { type IconProps } from '../../types';
import { IconWrapper } from './style';

export const ArrowRight = ({
  color,
  rotation,
  width = '24px',
  height = '24px',
  cursor,
}: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ? rotation : 0})`}
    >
      <path
        id="Path"
        d="M3 12H21.5"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M14.5 5L21.5 12L14.5 19"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconWrapper>
);
