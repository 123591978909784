import {
  type ReactElement,
  type ReactNode,
  useCallback,
  useEffect,
  type MouseEvent,
  useState,
  type ForwardedRef,
  type RefObject,
  Children,
  isValidElement,
  cloneElement,
  useRef,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { TabInner, TabsInner, TabsWrapper } from './styles';

export type TabsProps = {
  id: string;
  children: ReactElement<TabProps>[];
  // align with relevant values in justify-content where its applied
  justify?: 'left' | 'center' | 'right' | 'flex-start' | 'flex-end' | 'start' | 'end' | 'normal';
  animate?: boolean;
};

export type TabProps = Pick<TabsProps, 'animate'> & {
  children: ReactNode;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  to?: string;
  active?: boolean;
  disabled?: boolean;
};

export type TabsInnerProps = Omit<TabsProps, 'children'> & SelectorState;

export type SelectorState = {
  offsetLeft: number;
  width: number;
};

export type TabInnerProps = Pick<TabProps, 'active' | 'animate' | 'disabled'> & {
  forwardedRef?: ForwardedRef<HTMLLIElement>;
};

export const Tabs = ({ children, ...props }: TabsProps) => {
  const [selectorProps, setSelectorProps] = useState<SelectorState>({
    offsetLeft: 0,
    width: 0,
  });

  const callback = useCallback(
    (e: RefObject<HTMLLIElement>) =>
      e.current?.offsetWidth &&
      setSelectorProps({ offsetLeft: e.current.offsetLeft, width: e.current.offsetWidth }),
    [],
  );

  const renderChildren = useCallback(() => {
    return Children.map(children, (child, index) => {
      if (!isValidElement(child)) return null;

      // Typecasting here to be able to pass callback without typescipt (and developers) having to know or care.
      return cloneElement(
        child as unknown as ReactElement<TabProps>,
        {
          key: `${props.id}-tab-${index}`,
          callback,
          animate: props.animate,
          ['data-testid']: `${props.id}-tab-${index}`,
        } as unknown as TabProps,
      );
    });
  }, [children]);

  return (
    <TabsWrapper>
      <TabsInner {...selectorProps} role="tablist" {...props} data-testid={props.id}>
        {renderChildren()}
      </TabsInner>
    </TabsWrapper>
  );
};

export const Tab = ({ children, onClick, to, active, ...props }: TabProps) => {
  const elementRef = useRef<HTMLLIElement>(null);

  if (!onClick && !to) throw Error('Tab must have either an onClick or to (link) prop');

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (props.disabled) return;
    onClick && onClick(e);
  };

  useEffect(() => {
    /* eslint-disable react/prop-types */
    // @ts-expect-error Callback does exist I just don't want anyone to know about it
    const { callback } = props;

    /* eslint-enable react/prop-types */
    active && callback?.(elementRef);
  }, [active, elementRef]);

  return (
    <TabInner ref={elementRef} role="tab" active={active} {...props}>
      <RouterLink {...(onClick ? { onClick: handleClick } : null)} to={to && !onClick ? to : '#'}>
        {children}
      </RouterLink>
    </TabInner>
  );
};
