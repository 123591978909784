import { type ListDropdownItem } from '@stenarecycling/customer-portal-types';

const itemSearchFilter = (item: ListDropdownItem, search: string) => {
  const itemString = `${item.label}${item.value}${item.meta?.description}`.toLowerCase();

  return itemString.includes(search.toLowerCase());
};

const filterItems = (items: ListDropdownItem[], search: string) => {
  const searchLower = search.toLowerCase();
  const filteredItems: ListDropdownItem[] = [];

  for (const item of items) {
    const itemInSearch = itemSearchFilter(item, searchLower);

    let filteredChildren: ListDropdownItem[] = [];

    if (item.children) {
      filteredChildren = filterItems(item.children, searchLower);
    }

    if (itemInSearch || filteredChildren.length > 0) {
      filteredItems.push({ ...item, children: filteredChildren });
    }
  }

  return filteredItems;
};

export const searchItems = (items: ListDropdownItem[], search: string) => {
  // Only flatten items if necessary (i.e., if they have children)
  const flatItems = items.some((item) => item.children.length > 0)
    ? getFlatValueItems(items)
    : items;

  return filterItems(flatItems, search);
};

export const getFlatValueItems = (items: ListDropdownItem[]): ListDropdownItem[] => {
  const flatItems: ListDropdownItem[] = [];

  for (const item of items) {
    if (item.type === 'value') flatItems.push(item);

    if (item.children.length > 0) {
      // eslint-disable-next-line prefer-spread -- Bad performance
      flatItems.push.apply(flatItems, getFlatValueItems(item.children));
    }
  }

  // Empty children
  return flatItems;
};
