import {
  useCallback,
  type ChangeEvent,
  type InputHTMLAttributes,
  type ReactNode,
  cloneElement,
  Children,
  type ReactElement,
} from 'react';
import { Box } from '../../../components/Layout/Box';

import {
  RadioButtonContainer,
  StyledRadioButton,
  LabelWrapper,
  Label,
  Description,
  GroupContainer,
  GroupLabel,
  OptionsWrapper,
  ButtonSection,
  ErrorWrapper,
  RequiredIndicator,
} from './style';

export type RadioButtonProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'value' | 'name'
> & {
  label?: string;
  description?: string;
  value: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  children?: ReactNode;
  name?: string;
};

export const RadioButtonV2 = ({
  label,
  description,
  value,
  disabled,
  onChange,
  children,
  id,
  name,
  ...rest
}: RadioButtonProps) => {
  const toggleChecked = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.value);
      }
    },
    [onChange],
  );

  return (
    <RadioButtonContainer>
      <ButtonSection>
        <StyledRadioButton
          id={id ?? `id-${value}`}
          type="radio"
          onChange={toggleChecked}
          disabled={disabled}
          value={value}
          name={name}
          {...rest}
        />
        <LabelWrapper>
          {label && (
            <Label htmlFor={id ?? `id-${value}`} disabled={disabled}>
              {label}
            </Label>
          )}
          {description && <Description>{description}</Description>}
        </LabelWrapper>
      </ButtonSection>

      {!!children && children}
    </RadioButtonContainer>
  );
};

export type RadioButtonGroupProps = {
  children: ReactNode;
  name: string;
  label?: string;
  required?: boolean;
  errorMessage?: string;
  direction?: 'column' | 'row';
  inline?: boolean;
  onChange?: (value: string) => void;
  wrapLabel?: boolean;
};

export const RadioButtonGroupV2 = ({
  children,
  name,
  label,
  required,
  inline,
  errorMessage,
  direction = 'column',
  onChange,
  wrapLabel,
}: RadioButtonGroupProps) => {
  let elements: ReactElement[] = [];

  if (children) {
    elements = Children.toArray(children) as ReactElement[];
  }

  const hasError = !!errorMessage;

  return (
    <Box>
      <GroupContainer inline={inline}>
        {label && (
          <GroupLabel inline={inline} wrapLabel={wrapLabel}>
            {label}
            {!!required && <RequiredIndicator>&nbsp;*</RequiredIndicator>}
          </GroupLabel>
        )}
        <OptionsWrapper direction={direction} hasError={hasError}>
          {elements.length > 0 &&
            elements.map((element: ReactElement) => {
              const props = {
                name: name,
                onChange: onChange ? onChange : undefined,
              };

              return cloneElement(element, props);
            })}
        </OptionsWrapper>
      </GroupContainer>
      {hasError && <ErrorWrapper>{errorMessage}</ErrorWrapper>}
    </Box>
  );
};
