import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownContexts } from '../../../context/DropdownContext';
import { ListItemWrapper } from '../style';
import { Checkbox } from '../../../../Checkbox';
import { colors } from '../../../../../../themes';

export const ListItemSelectAll = () => {
  const { toggleSelectAll, isAllSelected } = useContext(DropdownContexts.useValues);
  const { items } = useContext(DropdownContexts.useItems);
  const { searchActive } = useContext(DropdownContexts.useSearch);
  const handleOnChange = () => {
    toggleSelectAll(items);
  };

  const { t } = useTranslation();

  if (searchActive) {
    return null;
  }

  return (
    <ListItemWrapper bottomBorder={true} isSelected={false} onClick={handleOnChange}>
      <Checkbox
        color={colors.primary.grey}
        onChange={handleOnChange}
        checked={isAllSelected(items)}
      />

      <span>{t('components.dropdown.select.all')}</span>
    </ListItemWrapper>
  );
};
