import { type ReactNode } from 'react';
import { StyledLabel } from './style';

export type LabelProps = {
  children: ReactNode;
  variant?: 'neutral' | 'warning' | 'error' | 'success' | 'info';
};

export const Label = ({ children, variant = 'neutral' }: LabelProps) => {
  return <StyledLabel variant={variant}>{children}</StyledLabel>;
};
