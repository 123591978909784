import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownContexts } from '../../../../../context/DropdownContext';
import { ListWrapper } from '../../../style';
import { useInactiveState } from '../../../../../../LocationPicker/hooks/useLocationPicker/useInactiveState';
import { useLocationCount } from '../../../../../../LocationPicker/hooks/useLocationPicker/useLocationCount';
import { type LocationListItemsType } from '../types';
import { LocationCountTemplate } from '../../../../../../LocationPicker/utils';
import {
  StyledChildCount,
  StyledChildWrapper,
  StyledDescription,
  StyledLocationGroup,
} from './styles';
import { LocationListItem } from './LocationListItem/LocationListItem';

/**
 * Recursive component that renders a group list item and its children
 * @param item
 * @param nested
 * @constructor
 */
export const LocationGroupList = ({
  item,
  nestedIndex = 0,
}: {
  item: LocationListItemsType;
  nestedIndex?: number;
}) => {
  const { searchActive } = useContext(DropdownContexts.useSearch);
  const { isChildOpen } = useContext(DropdownContexts.useDropdown);
  const { hideInactiveLocations } = useInactiveState();
  const { shouldUseLocationCount } = useLocationCount();
  const { t } = useTranslation();

  const shouldShow = useMemo(() => {
    const isInactive = !item.meta?.isActive;
    const hasNoActiveChildren = !item.meta?.activeChildren;

    const isUnselectable = item.meta?.isUnselectable;

    if (hideInactiveLocations && isInactive && hasNoActiveChildren) {
      return false;
    }

    if (hideInactiveLocations && isUnselectable && hasNoActiveChildren) {
      return false;
    }

    return !hideInactiveLocations || !!item.meta?.isActive || !!item.meta?.activeChildren;
  }, [hideInactiveLocations, item.meta]);

  const shouldShowChildren = useMemo(
    () => isChildOpen(item.value) || searchActive,
    [isChildOpen, item.value, searchActive],
  );

  const hasDescription = !!item.meta?.description.length;

  const childCount = useMemo(() => {
    const countRecursive = (item: LocationListItemsType): number => {
      return item.children.reduce((acc, child) => {
        if (!child.meta?.isActive) {
          return acc + countRecursive(child);
        }

        return acc + 1 + countRecursive(child);
      }, 0);
    };

    if (hideInactiveLocations) {
      return countRecursive(item);
    }

    return item.meta?.childCount ?? 0;
  }, [item, hideInactiveLocations]);

  const locationCount = useMemo(() => {
    const countRecursive = (item: LocationListItemsType): number => {
      return item.children.reduce(
        (acc, child) => {
          return acc + countRecursive(child);
        },
        item.meta?.isLocation && item.meta.isActive ? 1 : 0,
      );
    };

    if (hideInactiveLocations) {
      return countRecursive(item);
    }

    return item.meta?.locationCount ?? 0;
  }, [item, hideInactiveLocations]);

  const locationCountLabel =
    locationCount > 1 ? t('components.dropdown.locations') : t('components.dropdown.location');

  const hasChildCount = childCount > 0;

  const children = useMemo(() => {
    return (
      shouldShowChildren &&
      item.children.map((childItem) => (
        <LocationGroupList
          key={childItem.value + childItem.label}
          item={childItem}
          nestedIndex={nestedIndex + 1}
        />
      ))
    );
  }, [item.children, nestedIndex, shouldShowChildren]);

  const description = useMemo(() => {
    let description = item.meta?.description ?? '';

    if (locationCount > 0) {
      description = description.replace(
        LocationCountTemplate,
        `${locationCount} ${locationCountLabel}`,
      );
    } else {
      description = description.replace(LocationCountTemplate, '');
    }

    return description;
  }, [item.meta?.description, locationCount, locationCountLabel]);

  const locationCountSeparate = item.meta?.source !== 'FNO';

  if (!shouldShow) {
    return null;
  }

  return (
    <StyledLocationGroup nestedIndex={nestedIndex}>
      <ListWrapper>
        <LocationListItem item={item} nestedIndex={nestedIndex}>
          <StyledChildWrapper>
            {hasDescription && <StyledDescription>{description}</StyledDescription>}

            {locationCountSeparate && (
              <>
                {shouldUseLocationCount && locationCount > 1 && (
                  <StyledChildCount>
                    {locationCount} {locationCountLabel}
                  </StyledChildCount>
                )}

                {!shouldUseLocationCount && hasChildCount && (
                  <StyledChildCount>{`${childCount + 1} ${t(
                    'components.dropdown.locations',
                  )}`}</StyledChildCount>
                )}
              </>
            )}
          </StyledChildWrapper>
        </LocationListItem>
        {children}
      </ListWrapper>
    </StyledLocationGroup>
  );
};
