import { useEffect, useMemo, useState } from 'react';
import { type SortDir } from '../../../../../models/SortDir';
import { colors } from '../../../../../themes';
import { ArrowDown, ArrowUp, Info } from '../../../../Icons';
import { Tooltip } from '../../../../Data/Tooltip';
import { Paragraph } from '../../../../Typography';
import { type ColumnType } from '../../types';
import {
  CellWrapper,
  Header,
  HeaderWrapper,
  IconWrapper,
  SortIconWrapper,
  Label,
  ActionWrapper,
} from './style';

type HeaderCellProps = {
  tableSorted?: (colName: string, sortDir: SortDir) => void;
  tablePosX?: number;
  onSort?: (fieldName: string) => void;
  sortDirection?: SortDir;
} & ColumnType;

const HeaderCell = ({
  name,
  colSpan,
  sortable,
  onSort,
  subLabel,
  tooltip,
  justify,
  tablePosX,
  sortDirection,
  fieldName,
}: HeaderCellProps) => {
  const [isTooltipRight, setIsTooltipRight] = useState<boolean>(false);
  const [mousePosX, setMousePosX] = useState<number>(0);

  const sortIcon = useMemo(() => {
    if (sortDirection === 'ASC') {
      return (
        <SortIconWrapper>
          <ArrowUp />
        </SortIconWrapper>
      );
    }
    if (sortDirection === 'DESC') {
      return (
        <SortIconWrapper>
          <ArrowDown />
        </SortIconWrapper>
      );
    }

    return null;
  }, [sortDirection]);

  const handleTooltipPos = (mousePosX: number) => {
    setMousePosX(mousePosX);
  };

  const tooltipMarkup = useMemo(() => {
    if (tooltip) {
      return (
        <Tooltip
          hoverItem={
            <Info width="22px" height="22px" cursor="pointer" color={colors.primary.blue} />
          }
          isRight={isTooltipRight}
          mousePosX={handleTooltipPos}
        >
          <Paragraph variant="medium">{tooltip}</Paragraph>
        </Tooltip>
      );
    }

    return null;
  }, [tooltip, isTooltipRight]);

  const canSort = !!onSort && !!sortable;

  useEffect(() => {
    if (tablePosX && mousePosX + 300 > tablePosX) {
      setIsTooltipRight(true);
    } else {
      setIsTooltipRight(false);
    }
  }, [tablePosX, mousePosX]);

  return (
    <CellWrapper
      colSpan={colSpan}
      justify={justify}
      sortingDefined={canSort}
      onClick={
        canSort
          ? () => {
              onSort(fieldName);
            }
          : undefined
      }
    >
      <HeaderWrapper>
        <Header>{name}</Header>
        <Label>{subLabel}</Label>
      </HeaderWrapper>
      <ActionWrapper>
        {(canSort && sortIcon && <IconWrapper>{sortIcon}</IconWrapper>) || null}
        {tooltipMarkup}
      </ActionWrapper>
    </CellWrapper>
  );
};

export default HeaderCell;
