import { CircleWrapper, PictogramIconWrapper, getColor } from './style';
import { type PictogramCircleProps } from './Pictograms/types';
import { BackgroundCircle } from './Pictograms';

export const PictogramCircle = ({
  pictogram,
  variant = 'grey',
  light = false,
  size = '220px',
}: PictogramCircleProps) => {
  return (
    <CircleWrapper size={size} variant={variant}>
      <BackgroundCircle color={getColor(variant)} light={light} />
      <PictogramIconWrapper variant={variant}>{pictogram}</PictogramIconWrapper>
    </CircleWrapper>
  );
};
