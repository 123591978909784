import { type ReactNode } from 'react';
import { type Spacing } from '../../../themes/stena-recycling/spacing';
import { StyledBox } from './styles';

export type BoxProps = {
  p?: Spacing;
  pt?: Spacing;
  pb?: Spacing;
  m?: Spacing;
  mt?: Spacing;
  mb?: Spacing;
  mr?: Spacing;
  ml?: Spacing;
  children: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const Box = (props: BoxProps) => {
  return <StyledBox {...props} />;
};

export const TestComponent = () => {
  return <Box p="tiny">Test</Box>;
};
