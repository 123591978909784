import { spacing } from '../../../themes';
import { SkeletonLoaderAnimation, SkeletonLoaderWrapper, Animation } from './styles';

export type SkeletonLoaderProps = {
  height?: string | number;
  width?: string | number;
  shape?: 'circle' | 'square' | 'rounded';
  mt?: string | number;
  mb?: string | number;
  mr?: string | number;
  ml?: string | number;
};

export const SkeletonLoader = ({
  height = spacing.mediumLow,
  width = '100%',
  shape = 'square',
  mt,
  mb,
  mr,
  ml,
}: SkeletonLoaderProps) => {
  return (
    <SkeletonLoaderWrapper mt={mt} mb={mb} mr={mr} ml={ml}>
      <Animation height={height} width={width}>
        <SkeletonLoaderAnimation shape={shape} />
      </Animation>
    </SkeletonLoaderWrapper>
  );
};
