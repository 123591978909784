import { type ReactNode, useContext } from 'react';
import { DropdownContent, DropdownContentWrapper, DropdownV2Styled } from './styles';
import { DropdownHeader } from './components/DropdownHeader/DropdownHeader';
import { DropdownContexts } from './context/DropdownContext';
import { withDropdownProvider } from './hooks/withDropdownProvider/withDropdownProvider';
import { DropdownSkeletonLoader } from './components/DropdownSkeletonLoader';

export const DropdownV2 = withDropdownProvider(({ children }: { children: ReactNode }) => {
  const { loading = false } = useContext(DropdownContexts.props);
  const { open, dropdownRef } = useContext(DropdownContexts.useDropdown);

  return (
    <DropdownV2Styled ref={dropdownRef}>
      <DropdownHeader />

      <DropdownContentWrapper>
        {open && (
          <DropdownContent>
            {loading && <DropdownSkeletonLoader />}

            {!loading && children}
          </DropdownContent>
        )}
      </DropdownContentWrapper>
    </DropdownV2Styled>
  );
});

export * from './types/dropdown';
export * from './components';
