import { type ReactNode } from 'react';
import { DropdownProvider } from '../../context/DropdownContext';
import { type DropdownProviderProps } from '../../types/dropdown';

export const withDropdownProvider = (InputComponent: React.FC<{ children: ReactNode }>) => {
  const DropdownContent = (props: DropdownProviderProps) => {
    return (
      <DropdownProvider {...props}>
        <InputComponent>{props.children}</InputComponent>
      </DropdownProvider>
    );
  };

  DropdownContent.displayName = 'DropdownContent';

  return DropdownContent;
};
