import { type PictogramIconProps } from './types';

// finance_05
export const BarChartArrow = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.667 63.0771C26.667 62.5249 27.1147 62.0771 27.667 62.0771H36.0035C36.5558 62.0771 37.0035 62.5249 37.0035 63.0771V87.1385C37.0035 87.6908 36.5558 88.1385 36.0035 88.1385H27.667C27.1147 88.1385 26.667 87.6908 26.667 87.1385V63.0771ZM28.667 64.0771V86.1385H35.0035V64.0771H28.667Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.873 52.5381C39.873 51.9858 40.3208 51.5381 40.873 51.5381H49.2096C49.7619 51.5381 50.2096 51.9858 50.2096 52.5381V87.1288C50.2096 87.6811 49.7619 88.1288 49.2096 88.1288H40.873C40.3208 88.1288 39.873 87.6811 39.873 87.1288V52.5381ZM41.873 53.5381V86.1288H48.2096V53.5381H41.873Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.0791 51.1357C53.0791 50.5835 53.5268 50.1357 54.0791 50.1357H62.4156C62.9679 50.1357 63.4156 50.5835 63.4156 51.1357V87.129C63.4156 87.6813 62.9679 88.129 62.4156 88.129H54.0791C53.5268 88.129 53.0791 87.6813 53.0791 87.129V51.1357ZM55.0791 52.1357V86.129H61.4156V52.1357H55.0791Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.2849 46.6016C66.2849 46.0493 66.7326 45.6016 67.2849 45.6016H75.6214C76.1737 45.6016 76.6214 46.0493 76.6214 46.6016V87.1385C76.6214 87.6908 76.1737 88.1385 75.6214 88.1385H67.2849C66.7326 88.1385 66.2849 87.6908 66.2849 87.1385V46.6016ZM68.2849 47.6016V86.1385H74.6214V47.6016H68.2849Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.4912 38.2852C79.4912 37.7329 79.9389 37.2852 80.4912 37.2852H88.8278C89.38 37.2852 89.8278 37.7329 89.8278 38.2852V87.1389C89.8278 87.6911 89.38 88.1389 88.8278 88.1389H80.4912C79.9389 88.1389 79.4912 87.6911 79.4912 87.1389V38.2852ZM81.4912 39.2852V86.1389H87.8278V39.2852H81.4912Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.202 11.5453C80.2736 10.7199 81.0007 10.1089 81.826 10.1805L94.3506 11.267C94.7474 11.3014 95.1142 11.4922 95.3701 11.7974C95.6261 12.1025 95.7502 12.4969 95.7151 12.8936L94.6088 25.3984C94.5358 26.2236 93.8077 26.8334 92.9825 26.7604C92.1573 26.6874 91.5475 25.9592 91.6205 25.134L92.5944 14.1259L81.5667 13.1693C80.7414 13.0977 80.1304 12.3706 80.202 11.5453Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.5783 13.5119C94.1132 14.1445 94.0339 15.0909 93.4013 15.6258L63.0382 41.2972C62.7307 41.5571 62.3316 41.6826 61.9307 41.6453C61.5299 41.608 61.1608 41.4111 60.9066 41.0989L52.4287 30.6878L23.2422 55.3723C22.6097 55.9073 21.6633 55.8282 21.1283 55.1957C20.5933 54.5631 20.6724 53.6167 21.3049 53.0817L51.6582 27.4103C51.9657 27.1503 52.3648 27.0248 52.7657 27.0621C53.1666 27.0993 53.5358 27.2962 53.79 27.6085L62.268 38.0198L91.4644 13.3349C92.097 12.8 93.0435 12.8792 93.5783 13.5119Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  );
};
