import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownContexts } from '../../../../../../context/DropdownContext';

import { Checkbox } from '../../../../../../../Checkbox';
import { colors } from '../../../../../../../../../themes';
import { useInactiveState } from '../../../../../../../LocationPicker/hooks/useLocationPicker/useInactiveState';
import { SelectAllWrapper, StyledTitle, StyledWrapper } from './styles';

export const LocationSelectAll = () => {
  const { showInactiveToggle, toggleHideInactiveLocations, hideInactiveLocations } =
    useInactiveState();
  const { toggleSelectAll, isAllSelected } = useContext(DropdownContexts.useValues);
  const { items } = useContext(DropdownContexts.useItems);
  const { multiSelect } = useContext(DropdownContexts.props);
  const { searchActive } = useContext(DropdownContexts.useSearch);
  const { t } = useTranslation();

  const handleSelectAllClick = useCallback(() => {
    toggleSelectAll(items);
  }, [toggleSelectAll, items]);

  const handleToggleInactiveClick = useCallback(
    (e: React.MouseEvent | boolean) => {
      if (typeof e !== 'boolean') e.stopPropagation();
      toggleHideInactiveLocations();
    },
    [toggleHideInactiveLocations],
  );

  const activeItemCount = useMemo(
    () => items.filter((item) => item.meta?.isActive).length,
    [items],
  );
  const itemCount = hideInactiveLocations ? activeItemCount : items.length;

  const showSelectAll = multiSelect && itemCount > 1;

  if (searchActive || items.length === 0 || (!multiSelect && !showInactiveToggle)) {
    return null;
  }

  return (
    <StyledWrapper>
      {showSelectAll && (
        <SelectAllWrapper onClick={handleSelectAllClick}>
          <Checkbox
            color={colors.primary.grey}
            onChange={handleSelectAllClick}
            checked={isAllSelected(items, true)}
          />

          <StyledTitle>{t('components.dropdown.select.all')}</StyledTitle>
        </SelectAllWrapper>
      )}

      {showInactiveToggle && (
        <SelectAllWrapper onClick={handleToggleInactiveClick}>
          <Checkbox
            color={colors.primary.grey}
            onChange={handleToggleInactiveClick}
            checked={!hideInactiveLocations}
          />

          <StyledTitle>{t('components.dropdown.show.inactive')}</StyledTitle>
        </SelectAllWrapper>
      )}
    </StyledWrapper>
  );
};
