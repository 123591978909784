import { useContext, useMemo } from 'react';
import { DropdownContexts } from '../../../../context/DropdownContext';
import { ListWrapper } from '../../style';
import { LocationSelectAll } from './components/LocationSelectAll/LocationSelectAll';
import { LocationGroupList } from './components/LocationGroup';

export const LocationPickerList = () => {
  const { searchResults, searchActive } = useContext(DropdownContexts.useSearch);
  const { items } = useContext(DropdownContexts.useItems);

  const itemsToRender = useMemo(() => {
    return searchActive ? searchResults : items;
  }, [items, searchActive, searchResults]);

  return (
    <ListWrapper>
      <LocationSelectAll />

      {itemsToRender.map((item) => (
        <LocationGroupList key={item.value} item={item} />
      ))}
    </ListWrapper>
  );
};
